import {
  // RealtimePostgresUpdatePayload,
  createClient
} from '@supabase/supabase-js';
import {
  Database
  // Tables
} from 'database.types';
import {
  IFigmaVariable,
  IPageData,
  IStyleData
} from 'lam-toolkit-figma-plugin';
import { useEffect } from 'react';
import { useMVT } from '../stores';
import {
  IDownloadLink,
  IFont,
  IFontVariable,
  IProjectVariable,
  IVariables,
  TProjectVariableKey
} from '../types';
import { toKebabCase } from '@lam-agency/toolkit/utils';

const useSyncMVT = () => {
  const PROJECT_REF = process.env.GATSBY_SUPABASE_PROJECT_REF || '';
  const PROJECT_URL = `https://${PROJECT_REF}.supabase.co`;
  const PUBLIC_ANON_KEY = process.env.GATSBY_SUPABASE_PUBLIC_ANON_KEY || '';
  const PROJECT_ID = process.env.GATSBY_SUPABASE_PROJECT_ID || '';

  const supabase = createClient<Database>(PROJECT_URL, PUBLIC_ANON_KEY);

  const {
    setColors,
    setHasMVTDataLoaded,
    setPageData,
    setVariables,
    setClient,
    setTypestyles,
    setFonts
  } = useMVT();

  const parseVariablesFromSupabaseData = (variables: IFigmaVariable[]) => {
    const fonts: IFontVariable[] = [];
    const downloadLinks: IDownloadLink[] = [];
    const project: IProjectVariable[] = [];

    // Todo - remove this, as superceeded by node name parsing logic
    const parseDownloadLink: (
      titleWithoutCategory: string,
      url: string
    ) => IDownloadLink | null = (titleWithoutCategory, url) => {
      switch (titleWithoutCategory) {
        case 'logo':
          return {
            description: 'All Brand Logos (.eps, .png, .svg)',
            title: 'Logo Kit',
            url
          };
        case 'colours':
          return {
            description: 'Brand Colour Palette (.ase)',
            title: 'Colour Kit',
            url
          };
        case 'fonts':
          return {
            description: 'Font Pack (.ttf, .otf, .eot, .woff, .woff2, .svg)',
            title: 'Font Kit',
            url
          };
        case 'art-direction':
          return {
            description: 'All Images and Art Direction (.png)',
            title: 'Art Direction Kit',
            url
          };
        case 'illustrations':
          return {
            description: 'All Illustrations (.png)',
            title: 'Illustration Kit',
            url
          };
      }

      console.error(`Configure Download Link for ${titleWithoutCategory}`);
      return null;
    };

    variables.forEach(({ key, value }) => {
      const category = key.split('/')[0];
      const titleWithoutCategory = key.split('/').slice(1).join('/');

      switch (category) {
        case 'font-url': {
          const [fontFamily, fontStyle, format] =
            titleWithoutCategory.split('/');

          const fileName = toKebabCase(`${fontFamily}-${fontStyle}.${format}`);

          fonts.push({
            format,
            fontFamily,
            fontStyle,
            downloadUrl: value,
            fileName
          });
          return;
        }
        case 'download-url': {
          const downloadLink = parseDownloadLink(titleWithoutCategory, value);
          if (downloadLink) {
            downloadLinks.push(downloadLink);
          }
          return;
        }
        case 'project': {
          project.push({
            key: titleWithoutCategory as TProjectVariableKey,
            value
          });
          return;
        }
      }
    });

    const parsedVariables: IVariables = {
      project,
      fonts,
      downloadLinks
    };

    setVariables(parsedVariables);
  };

  useEffect(() => {
    const getMVTDataFromSupabase = async () => {
      const { data, error } = await supabase
        .from('mvt_data')
        .select('style_data, page_data')
        .eq('id', PROJECT_ID);

      if (error) {
        console.error('Error fetching data:', error);
        return;
      }

      const { colors, variables, typestyles } = data[0]
        .style_data as unknown as IStyleData;
      const pageData = data[0].page_data as unknown as IPageData[];

      const fonts: IFont[] = [];
      typestyles.forEach((typestyle) => {
        const font = typestyle.font;
        const fontExists = fonts.find(
          (fontItem) =>
            fontItem.family === font.family && fontItem.style === font.style
        );
        if (!fontExists) {
          fonts.push(font);
        }
      });
      const alphabeticalFonts = fonts.sort((a, b) =>
        a.family.localeCompare(b.family)
      );

      setFonts(alphabeticalFonts);
      setColors(colors);
      setTypestyles(typestyles);
      setPageData(pageData);
      setHasMVTDataLoaded(true);
      parseVariablesFromSupabaseData(variables);
    };

    getMVTDataFromSupabase();
  }, []);

  // const handleMVTRealtimeUpdate = (
  //   payload: RealtimePostgresUpdatePayload<Tables<'mvt_data'>>
  // ) => {
  //   const styleData = payload.new.style_data as unknown as IStyleData;
  //   const pageData = payload.new.page_data as unknown as IPageData[];

  //   if (styleData) {
  //     const { colors, variables, typestyles } = styleData;
  //     setColors(colors);
  //     setTypestyles(typestyles);
  //     parseVariablesFromSupabaseData(variables);
  //   }

  //   if (pageData) {
  //     setPageData(pageData);
  //   }
  // };

  // useEffect(() => {
  //   supabase
  //     .channel('projects')
  //     .on(
  //       'postgres_changes',
  //       { event: 'UPDATE', schema: 'public', table: 'mvt_data' },
  //       handleMVTRealtimeUpdate
  //     )
  //     .subscribe();
  // }, []);

  useEffect(() => {
    setClient(supabase);
  }, []);
};

export default useSyncMVT;
