import { create } from 'zustand';
import { IColor, IPageData, ITypestyle } from 'lam-toolkit-figma-plugin';
import { IFont, IVariables } from '../types';
import { SupabaseClient } from '@supabase/supabase-js';
import { toKebabCase } from '@lam-agency/toolkit/utils';

interface IMVT {
  hasMVTDataLoaded: boolean;
  setHasMVTDataLoaded: (hasLoaded: boolean) => void;
  colors: IColor[];
  setColors: (colors: IColor[]) => void;
  typestyles: ITypestyle[];
  setTypestyles: (typestyles: ITypestyle[]) => void;
  variables: IVariables;
  setVariables: (variables: IVariables) => void;
  fonts: IFont[];
  setFonts: (fonts: IFont[]) => void;
  pageData: IPageData[];
  setPageData: (pageData: IPageData[]) => void;
  client: SupabaseClient | null;
  setClient: (client: SupabaseClient) => void;
  projectString: string;
}

export const useMVT = create<IMVT>((set) => ({
  client: null,
  setClient: (client: SupabaseClient) => set({ client }),
  colors: [],
  setColors: (colors: IColor[]) => set({ colors }),
  typestyles: [],
  setTypestyles: (typestyles: ITypestyle[]) => set({ typestyles }),
  fonts: [],
  setFonts: (fonts: IFont[]) => set({ fonts }),
  variables: {
    fonts: [],
    project: [],
    downloadLinks: []
  },
  setVariables: (variables: IVariables) => {
    const projectNameVariable = variables.project.find(
      (variable) => variable.key === 'name'
    );

    set({
      variables,
      projectString: toKebabCase(projectNameVariable?.value || '')
    });
  },
  projectString: '',
  hasMVTDataLoaded: false,
  setHasMVTDataLoaded: (hasMVTDataLoaded: boolean) => set({ hasMVTDataLoaded }),
  pageData: [],
  setPageData: (pageData: IPageData[]) => set({ pageData })
}));
