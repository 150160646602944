import React, { Fragment } from 'react';
import { Button, LayoutProvider, TSVG } from '@lam-agency/toolkit/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';
import { usePages } from '@lam-agency/toolkit/hooks';
import { Link } from 'gatsby';

const Footer = () => {
  const { pageCategories } = usePages();

  const useClientLogo =
    getCSSVariableValue('--footer-use-client-logo') === 'true';
  const hideLAMLink = getCSSVariableValue('--footer-hide-lam-link') === 'true';

  const currentYear = new Date().getFullYear();

  const copyrightSVG: () => TSVG = () => {
    switch (currentYear) {
      case 2024:
        return 'year2024';
      case 2025:
        return 'year2025';
      case 2026:
        return 'year2026';
      case 2027:
        return 'year2027';
      case 2028:
        return 'year2028';
      case 2029:
        return 'year2029';
      case 2030:
        return 'year2030';
      case 2031:
        return 'year2031';
      case 2032:
        return 'year2032';
      case 2033:
        return 'year2033';
      case 2034:
      default:
        return 'year2034';
      // If this code makes it to 2035, we'll need to make some more SVGs
    }
  };

  return (
    <footer className={styles.container}>
      <LayoutProvider paddingX paddingY grid className={styles.grid}>
        <SVG
          svg={useClientLogo ? 'logo' : 'wordmark'}
          className={styles.wordmark}
        />

        {pageCategories.map((category, i) => (
          <Fragment key={i}>
            <h2 className={cn('caption', styles.categoryTitle)}>
              {category.title}
            </h2>

            <div className={styles.categoryPages}>
              {category.pages.map((page, j) => (
                <Link
                  className={cn('button', styles.pageLink)}
                  key={j}
                  to={`/${page.slug}`}
                >
                  {page.name}
                </Link>
              ))}
            </div>
          </Fragment>
        ))}

        {/* Note - we're using SVGs for these buttons so we can use LAM brand font, 
        without having to load a whole new font just for the footer */}
        <div className={styles.buttons}>
          <div>
            {!hideLAMLink && (
              <Button
                color="var(--footer-text-color)"
                backgroundColor="var(--color-background-color)"
                to="https://www.loveandmoney.agency/"
              >
                <SVG className={styles.madeWithLAMSVG} svg="madeWithLAM" />
              </Button>
            )}
          </div>

          <SVG className={styles.yearSVG} svg={copyrightSVG()} />
        </div>
      </LayoutProvider>
    </footer>
  );
};

export default Footer;
